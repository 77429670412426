import Breadcrumbs from "components/Common/Breadcrumb";
import React, { useCallback, useEffect, useState } from "react";
import { MetaTags } from "react-meta-tags";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  ButtonDropdown,
  DropdownToggle,
  DropdownItem,
  DropdownMenu,
  Collapse,
  Container,
  FormGroup,
  Input,
  Row, Alert, Table,
} from "reactstrap";

import {
  // SaleOrder
  getPagingSaleOrder,
  addSaleOrder,
  updateSaleOrder,
  getSaleOrderById,
  deleteSaleOrder,
  addSaleOrderInvoice,
  clearSaleOrderInvoiceNotify,
  changeSaleOrderStatus,
  generateStockOutFromSaleOrder,
} from "store/actions";
import ConfirmDeleteModal from "components/Common/ConfirmDeleteModal";

import { saleOrderService } from "services/sale-order-service";
import { debounce } from "lodash";
import ModalForm from "containers/uom/ModalForm";
import SaleOrderTable from "containers/sale-order/SaleOrderTable";
import Select from "react-select";
import {
  timestampFileName,
  hasAccess,
  saleOrderPaymentStatusOptions,
  saleOrderStatusFilter,
  toastMessage, 
  saleOrderTypeStatusOptions, 
  saleOrderDeliveryStatusFilterOptions,
    
} from "../../helpers/utils";
import GenerateInvoiceModal from "../../containers/sale-order-invoice/GenerateInvoiceModal";
import { permission } from "constants/permission";
import ModalConfirmation from "../../components/Common/ModalConfirmation";
import { generateStockOutFromSaleOrderRequest } from "../../store/sale-order/saga";
import SaleOrderGenerateStockOutResultModal from "../../containers/sale-order/SaleOrderGenerateStockOutResultModal";
import UserSelect from "../../containers/user/UserSelect";

const SaleOrderList = props => {
  const [ id, setId ] = useState(null);
  const [ term, setTerm ] = useState("");
  const [ page, setPage ] = useState(1);
  const [ pageSize, setPageSize ] = useState(10);
  const [ modalEditIsOpen, setModalEditIsOpen ] = useState(false);
  const [ modalAddIsOpen, setModalAddIsOpen ] = useState(false);
  const [ modalConfirmDeleteIsOpen, setModalConfirmDeleteIsOpen ] =
      useState(false);
  const [ modalGenerateInvoice, setModalGenerateInvoice ] = useState(false);
  const [ modalConfirmGenerateStockOut, setModalConfirmGenerateStockOut ] = useState(false)
  const [ modalGenerateStockOutResult, setModalGenerateStockOutResult ] = useState(false);
  const [ generateStockOutResult, setGenerateStockOutResult ] = useState(null)
  const [ isCollapse, setIsCollapse ] = useState(false);
  const [ statuses, setStatuses ] = useState([]);
  const [ myOrder, setMyOrder ] = useState(true);
  const [ paymentStatuses, setPaymentStatuses ] = useState([]);
  const [ deliveryStatuses, setDeliveryStatuses ] = useState([]);
  const [ types, setTypes ] = useState([])
  const [ selectedSaleOrder, setSelectedSaleOrder ] = useState(null);
  const [ moreActionIsOpen, setMoreActionIsOpen ] = useState(false);
  const [ sellerId, setSellerId ] = useState(null);

  const {
    getPagingSaleOrder,
    addSaleOrder,
    addSaleOrderInvoice,
    clearSaleOrderInvoiceNotify,
    changeSaleOrderStatus,
    history,
    updateSaleOrder,
    getSaleOrderById,
    deleteSaleOrder,
    saleOrder,
    saleOrderInvoice,
  } = props;

  const { items, item, loading, itemCount, pageCount } = saleOrder;
  
  const debouncedFetchData = useCallback(
      debounce(term => {
        setTerm(term);
      }, 1000),
      []
  );

  const toggleMoreAction = () => {
    setMoreActionIsOpen(prevState => !prevState);
  };
  
  useEffect(() => {
    populateSaleOrders();
  }, [
    sellerId,
    page,
    pageSize,
    term,
    myOrder,
    statuses,
    paymentStatuses,
    deliveryStatuses,
    types
  ]);

  const populateSaleOrders = () => {
    getPagingSaleOrder({
      page,
      pageSize,
      term,
      myOrder,
      sellerId: sellerId?.value,
      statuses: statuses
          ? statuses.map(status => {
            return status.value;
          })
          : null,
      paymentStatuses: paymentStatuses
          ? paymentStatuses.map(status => {
            return status.value;
          })
          : null,
      deliveryStatuses: deliveryStatuses
          ? deliveryStatuses.map(status => {
            return status.value;
          })
          : null,
      types: types
          ? types.map(status => {
            return status.value;
          })
          : null,
    });
  }

  useEffect(() => {
    if (saleOrderInvoice.message) {
      toastMessage(saleOrderInvoice.message);
      clearSaleOrderInvoiceNotify();
    }
  }, [ saleOrderInvoice.message ]);
  
  const handleOnEdit = id => {
    history.push(`/sale-order/${ id }`);
  };

  const handleUpdate = data => {
    updateSaleOrder({
      data,
      queryParams: {},
    });
    setModalEditIsOpen(false);
  };

  const handleOnAddNew = () => {
    history.push("/sale-order/add-new");
  };

  const handleSubmit = data => {
    addSaleOrder({
      data,
      queryParams: {},
    });
    setModalAddIsOpen(false);
  };

  const handleConfirmDelete = id => {
    setId(id);
    setModalConfirmDeleteIsOpen(true);
  };

  const handleDelete = () => {
    deleteSaleOrder({
      data: id,
      queryParams: {},
    });
    setModalConfirmDeleteIsOpen(false);
  };

  const handleSelectChange = (valueType, actionMeta) => {
    const { name } = actionMeta;
    if (name === "status") {
      setStatuses(valueType);
    } else if (name === "myOrder") {
      setMyOrder(valueType ? valueType.value : false);
    } else if (name === "paymentStatus") {
      setPaymentStatuses(valueType);
    } else if (name === "deliveryStatus") {
      setDeliveryStatuses(valueType);
    } else if ( name === "types") {
      setTypes(valueType);
    }
    setPage(1);
  };

  const handleExportToExcel = () => {
    saleOrderService.exportToExcelRequest(
        {
          term: term ?? "",
          myOrder,
          statuses: statuses
              ? statuses.map(status => status.value)
              : null,
          paymentStatuses: paymentStatuses
              ? paymentStatuses.map(status => status.value)
              : null,
          deliveryStatuses: deliveryStatuses
              ? deliveryStatuses.map(status => status.value)
              : null,
          types: types
              ? types.map(status => status.value)
              : null,
        },
        `${ timestampFileName() }_Sale_Order_List.xlsx`
    );
  };
  
  const handleConfirmGenerateInvoice = item => {
    setModalGenerateInvoice(true);
    setSelectedSaleOrder(item);
  };

  const handleGenerateInvoice = item => {
    addSaleOrderInvoice(item);
    setModalGenerateInvoice(false);
  };
  
  const handleStatusChange = data => {
    changeSaleOrderStatus({
      data,
      queryParams: {
        page,
        pageSize,
        term,
        myOrder,
        statuses: statuses
            ? statuses.map(status => {
              return status.value;
            })
            : null,
        paymentStatuses: paymentStatuses
            ? paymentStatuses.map(status => {
              return status.value;
            })
            : null,
        deliveryStatuses: deliveryStatuses
            ? deliveryStatuses.map(status => {
              return status.value;
            })
            : null,
        types: types
            ? types.map(status => {
              return status.value;
            })
            : null,
      },
    });
  };

  const myOrderOptions = [
    { value: true, label: "My Order" },
    { value: false, label: "All Order" }
  ];

  const confirmGenerateStockOutHandler = (saleOrder) => {
    setId(saleOrder.id);
    setModalConfirmGenerateStockOut(true);
    setGenerateStockOutResult(null);
  }

  const handleOnDownloadLabel = id => {
    let filename = `${ timestampFileName() }_Label_Print.pdf`;
    saleOrderService.downloadLabelRequest(id, filename);
  };


  const generateStockOutHandler = () => {
    generateStockOutFromSaleOrderRequest(id).then(res => {
      if (res) {
        setModalGenerateStockOutResult(true);
        setGenerateStockOutResult(res);
      }
    })
  }

  const generateStockOutResultModalHandler = () => {
    setModalGenerateStockOutResult(false);
    setModalConfirmGenerateStockOut(false);
    
    populateSaleOrders();
  }

  return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>Sale Order | CR TechSoft</title>
          </MetaTags>
          <Container fluid>
            <Breadcrumbs title="Sale Order" breadcrumbItem="Sale Order List"/>
            <Row>
              <Col md={ 12 }>
                <Card className="mb-1 rounded-3">
                  <CardHeader className="bg-transparent border pb-0 pt-3">
                    <Row className="g-1">
                      <Col md={ 2 }>
                        <div className="search-box me-2 mb-2 d-inline-block">
                          <div className="position-relative">
                            <Input
                                type="text"
                                placeholder="Quick Search ..."
                                onChange={ e => {
                                  debouncedFetchData(e.target.value);
                                } }
                            ></Input>
                            <i className="fas fa-search search-icon"/>
                          </div>
                        </div>
                      </Col>

                      { hasAccess(permission.myOrder.enable) && (
                          <Col>
                            <FormGroup>
                              <Select
                                  name="myOrder"
                                  value={ myOrderOptions.find(option => option.value === myOrder) }
                                  onChange={ handleSelectChange }
                                  options={ myOrderOptions }
                                  classNamePrefix="select2-selection"
                                  placeholder="Order Type"
                                  isClearable
                              />
                            </FormGroup>
                          </Col>
                      )
                      }

                      <Col>
                        <UserSelect
                            name="sellerId"
                            value={ sellerId }
                            roleNames={ [
                              "Seller",
                            ] }
                            onChange={ option => {
                              setSellerId(option);
                            } }
                            placeholder={ "Select Seller" }
                          />
                      </Col>

                      <Col>
                        <FormGroup>
                          <Select
                              name="status"
                              value={ statuses }
                              onChange={ handleSelectChange }
                              options={ saleOrderStatusFilter }
                              classNamePrefix="select2-selection"
                              placeholder="Status"
                              isClearable
                              isMulti
                          />
                        </FormGroup>
                      </Col>
                      <Col>
                        <FormGroup>
                          <Select
                              name="paymentStatus"
                              value={ paymentStatuses }
                              onChange={ handleSelectChange }
                              options={ saleOrderPaymentStatusOptions }
                              classNamePrefix="select2-selection"
                              placeholder="Payment Status"
                              isClearable
                              isMulti
                          />
                        </FormGroup>
                      </Col>
                      <Col md={ 3 }>
                        <div className="text-sm-end me-3">
                          <Button
                              type="button"
                              color="primary"
                              className="me-1"
                              onClick={ handleOnAddNew }
                          >
                            <i className="fas fa-plus"/> Add New
                          </Button>

                          <ButtonDropdown
                              isOpen={ moreActionIsOpen }
                              toggle={ toggleMoreAction }
                          >
                            <DropdownToggle caret color="primary" outline>
                              <i className="mdi mdi-dots-vertical"></i>
                            </DropdownToggle>
                            <DropdownMenu className="dropdown-menu-end">
                              <DropdownItem
                                  onClick={ handleExportToExcel }
                                  className="text-primary"
                              >
                                <i className="fas fa-file-excel me-1"/>{ " " }
                                Export to Excel
                              </DropdownItem>
                            </DropdownMenu>
                          </ButtonDropdown>
                        </div>
                      </Col>
                    </Row>
                    <div
                        style={ {
                          position: "absolute",
                          top: "1rem",
                          right: "0.5rem",
                          cursor: "pointer",
                        } }
                        onClick={ () => setIsCollapse(!isCollapse) }
                    >
                    <span className="font-size-24 text-primary ps-2 pe-2">
                      <i
                          className={
                            isCollapse ? "fas fa-angle-up" : "fas fa-angle-down"
                          }
                      ></i>
                    </span>
                    </div>
                  </CardHeader>
                  <Collapse isOpen={ isCollapse }>
                    <CardBody className="pb-1">
                      <Row>
                        <Col md={ 4 }>
                          <FormGroup>
                            <Select
                                name="deliveryStatus"
                                value={ deliveryStatuses }
                                onChange={ handleSelectChange }
                                options={ saleOrderDeliveryStatusFilterOptions }
                                classNamePrefix="select2-selection"
                                placeholder="Delivery Status"
                                isClearable
                                isMulti
                            />
                          </FormGroup>
                        </Col>
                        <Col md={ 4 }>
                          <FormGroup>
                            <Select
                                name="types"
                                value={ types }
                                onChange={ handleSelectChange }
                                options={ saleOrderTypeStatusOptions }
                                classNamePrefix="select2-selection"
                                placeholder="Type"
                                isClearable
                                isMulti
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                    </CardBody>
                  </Collapse>
                </Card>
              </Col>
              <Col md={ 12 }>
                { loading ? (
                    <>
                      <div className="d-flex justify-content-center">
                        <div
                            className="spinner-border text-primary m-1"
                            role="status"
                        >
                          <span className="sr-only">Loading...</span>
                        </div>
                      </div>
                    </>
                ) : (
                    <>
                      { items && (
                          <SaleOrderTable
                              items={ items }
                              itemCount={ itemCount }
                              page={ page }
                              totalPage={ pageCount }
                              loading={ loading }
                              defaultPageSize={ pageSize }
                              onEdit={ handleOnEdit }
                              onConfirmDelete={ handleConfirmDelete }
                              onDownloadPrintLabel={ handleOnDownloadLabel }
                              onGenerateInvoice={ handleConfirmGenerateInvoice }
                              onGenerateStockOut={ confirmGenerateStockOutHandler }
                              onChangePage={ page => setPage(page) }
                              onStatusChange={ handleStatusChange }
                              onPageSizeChange={ pageSize => {
                                setPage(1);
                                setPageSize(pageSize);
                              } }
                          />
                      ) }
                    </>
                ) }

                { item && (
                    <ModalForm
                        title={ "Edit UoM" }
                        item={ item }
                        isOpen={ modalEditIsOpen }
                        toggle={ () => setModalEditIsOpen(!modalEditIsOpen) }
                        onSubmit={ handleUpdate }
                    />
                ) }
                <ModalForm
                    title={ "Add UoM" }
                    isOpen={ modalAddIsOpen }
                    toggle={ () => setModalAddIsOpen(!modalAddIsOpen) }
                    onSubmit={ handleSubmit }
                />
                <ConfirmDeleteModal
                    title="Confirm Delete"
                    isOpen={ modalConfirmDeleteIsOpen }
                    toggle={ () =>
                        setModalConfirmDeleteIsOpen(!modalConfirmDeleteIsOpen)
                    }
                    onSubmit={ handleDelete }
                />
                <ModalConfirmation
                    title="Confirm to generate Stock Out"
                    isOpen={ modalConfirmGenerateStockOut }
                    toggle={ () => setModalConfirmGenerateStockOut(false) }
                    onSubmit={ generateStockOutHandler }>
                  <Alert color="warning">Are you sure want to generate stock out from this Sale Order?</Alert>
                </ModalConfirmation>
                <SaleOrderGenerateStockOutResultModal
                    title={"Generate Stock Out Result"}
                    isOpen={ modalGenerateStockOutResult }
                    result={generateStockOutResult}
                    toggle={ () => {
                      setModalGenerateStockOutResult(false);
                    }}
                    onSubmit={generateStockOutResultModalHandler}
                />
                <GenerateInvoiceModal
                    title={ "Generate Invoice" }
                    isOpen={ modalGenerateInvoice }
                    toggle={ () => setModalGenerateInvoice(false) }
                    saleOrder={ selectedSaleOrder }
                    onSubmit={ handleGenerateInvoice }
                />
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
  );
};

SaleOrderList.propTypes = {
  saleOrder: PropTypes.object,
  getPagingSaleOrder: PropTypes.func,
  addSaleOrder: PropTypes.func,
  updateSaleOrder: PropTypes.func,
  getSaleOrderById: PropTypes.func,
  deleteSaleOrder: PropTypes.func,
  clearSaleOrderInvoiceNotify: PropTypes.func,
};

const mapStateToProps = ({ saleOrder, saleOrderInvoice }) => {
  return {
    saleOrder,
    saleOrderInvoice,
  };
};

export default withRouter(
    connect(mapStateToProps, {
      getPagingSaleOrder,
      addSaleOrderInvoice,
      addSaleOrder,
      updateSaleOrder,
      getSaleOrderById,
      deleteSaleOrder,
      clearSaleOrderInvoiceNotify,
      changeSaleOrderStatus,
      generateStockOutFromSaleOrder
    })(SaleOrderList)
);
