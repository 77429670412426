import React, { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import {
  getWalletTransactions,
  addNewWalletTransaction,
  getWalletTransactionById,
  updateWalletTransaction,
  deleteWalletTransaction,
  clearWalletTransactionError,
} from "store/actions";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Input,
  Row,
  Table,
  FormGroup,
  Badge,
} from "reactstrap";
import NumberFormat from "react-number-format";
import CustomPagination from "components/Common/CustomPagination";
import ModalForm from "containers/wallet-transaction/ModalForm";
import ConfirmDeleteModal from "components/Common/ConfirmDeleteModal";
import { toastMessage, walletTransactionStatusFilter, walletTransactionFilter } from "helpers/utils";
import { debounce } from "lodash";
import Select from "react-select";
import moment from "moment";

const WalletTransactionTab = props => {
  const [selectedId, setSelectedId] = useState(null);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [ term, setTerm ] = useState('');
  const [ statuses, setStatuses ] = useState([]);
  const [ transactions, setTransactions ] = useState([]);
  const [modalAddIsOpen, setModalAddIsOpen] = useState(false);
  const [modalEditIsOpen, setModalEditIsOpen] = useState(false);
  const [modalConfirmDeleteIsOpen, setModalConfirmDeleteIsOpen] = useState(false);

  const {
    getWalletTransactions,
    addNewWalletTransaction,
    getWalletTransactionById,
    updateWalletTransaction,
    deleteWalletTransaction,
    clearWalletTransactionError,
    items,
    item,
    message,
    itemCount,
    pageCount,
    loading,
    walletId,
  } = props;

  const debouncedFetchData = useCallback(debounce(term => {
    setTerm(term)
  }, 1000), [])

  useEffect(() => {
    getWalletTransactions({ 
      walletId, 
      page, 
      pageSize, 
      term,     
      statuses: statuses.map(status => status.value),
      types: transactions.map(type => type.value) });
  }, [walletId, page, pageSize, getWalletTransactions, term, statuses, transactions,]);

  useEffect(() => {
    if (message) {
      toastMessage(message);
      clearWalletTransactionError();
    }
  }, [message, clearWalletTransactionError]);

  const handleSubmit = data => {
    console.log("Data 111111:", data); 
    addNewWalletTransaction({ data: { ...data, walletId }, queryParam: { walletId } });
    setModalAddIsOpen(false);
    getWalletTransactions({ walletId, page, pageSize });
  };

  const handleUpdate = data => {
    console.log("Data 22222:", data);
    updateWalletTransaction({ data, queryParam: { walletId } });
    setModalEditIsOpen(false);
    getWalletTransactions({ walletId, page, pageSize });
  };

  const handleDelete = () => {
    deleteWalletTransaction({ data: selectedId, queryParam: { walletId } });
    setModalConfirmDeleteIsOpen(false);
    getWalletTransactions({ walletId, page, pageSize });
  };
  
  const displayPaymentStatus = item => {
    const { status, statusName } = item;
    let color = "info";

    switch (status) {
      case 0:
        color = "info";
        break;
      case 1:
        color = "warning";
        break;
      case 2:
        color = "success";
        break;
      case 3:
        color = "danger";
        break;
    }

    return (
      <Badge className="p-2" color={color}>
        <span>{statusName}</span>
      </Badge>
    );
  };

  const handleSelectChange = (valueType, actionMeta) => {
    const { name } = actionMeta;
    if (name === "status") {
      setStatuses(valueType);
    } else if (name === "transaction") {
      setTransactions(valueType);
    } 
    setPage(1);
  };

  return (
    <div>
      <Card className="mb-1">
        <CardHeader className="bg-transparent border-bottom">
          <Row className="g-1">
            <Col md={2}>
              <div className="search-box me-2 mb-2 d-inline-block">
                <div className="position-relative">
                    <Input
                        type="text"
                        placeholder="Quick Search ..."
                        onChange={ (e) => {
                            debouncedFetchData(e.target.value)
                        } }
                    ></Input>
                  <i className="fas fa-search search-icon" />
                </div>
              </div>
            </Col>
            <Col md={4}>
              <FormGroup>
                <Select
                    name="status"
                    value={ statuses }
                    onChange={ handleSelectChange }
                    options={ walletTransactionStatusFilter }
                    classNamePrefix="select2-selection"
                    placeholder="Status"
                    isClearable
                    isMulti
                />
              </FormGroup>
            </Col>
            <Col md={4}>
              <FormGroup>
                <Select
                    name="transaction"
                    value={ transactions }
                    onChange={ handleSelectChange }
                    options={ walletTransactionFilter }
                    classNamePrefix="select2-selection"
                    placeholder="Transaction"
                    isClearable
                    isMulti
                />
              </FormGroup>
            </Col>
            <Col md={2}>
              <div className="text-sm-end">
                <Button color="primary" onClick={() => setModalAddIsOpen(true)}>
                  <i className="fas fa-plus" /> Add New
                </Button>
              </div>
            </Col>
          </Row>
        </CardHeader>
      </Card>
      <Card>
        <CardBody>
          <div className="table-rep-plugin">
            {loading ? (
              <div className="d-flex justify-content-center">
                <div className="spinner-border text-primary m-1" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
              </div>
            ) : (
              <Table className="table table-striped table-bordered table-nowrap">
                <thead>
                  <tr>
                    <th style={{ width: "90px" }}>#</th>
                    <th style={{ width: "370px" }}>Transaction Type</th>
                    <th style={{ width: "200px" }}>Amount</th>
                    <th style={{ width: "370px" }}>Status</th>
                    <th style={{ width: "370px" }}>Created Date</th>
                    <th style={{ width: "320px" }}>Description</th> 
                    <th style={{ width: "120px" }}>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {items.map((item, index) => (
                    <tr key={item.id}>
                      <td>{(page - 1) * pageSize + (index + 1)}</td>
                      <td>{item.typeName}</td>
                      <td className={"text-end"}>
                        <NumberFormat
                          displayType="text"
                          value={
                            item.typeName === "Deposit"
                              ? `+${Number(item.amount).toFixed(2)}`
                              : item.typeName === "Withdrawal"
                              ? `-${Number(item.amount).toFixed(2)}`
                              : Number(item.amount).toFixed(2) 
                          }
                          thousandSeparator={true}
                          fixedDecimalScale={false}
                          readOnly
                        />
                      </td>
                      <td>{displayPaymentStatus(item)}</td>
                      <td>{item.description}</td>
                      <td>
                        {moment
                          .utc(item.createdDate)
                          .local()
                          .format("DD-MMM-yyyy h:mm:ss a")}
                      </td> 
                      <td>
                        <div className="d-flex gap-3 align-items-center">
                          {item.walletTransactionAttachments && item.walletTransactionAttachments.length > 0 && (
                            <a 
                              href={item.walletTransactionAttachments[0].url} 
                              download={item.walletTransactionAttachments[0].fileName} 
                              target="_blank" 
                              rel="noopener noreferrer"
                              className="me-2"
                            >
                              <i className="fas fa-download"></i>
                            </a>
                          )}

                          {item.statusName !== "Confirmed" && (
                            <>
                              <Link to="#" onClick={() => {
                                getWalletTransactionById(item.id);
                                setModalEditIsOpen(true);
                              }}>
                                <i className="mdi mdi-pencil font-size-18" />
                              </Link>
                         
                              <Link to="#" onClick={() => {
                                setSelectedId(item.id);
                                setModalConfirmDeleteIsOpen(true);
                              }}>
                                <i className="mdi mdi-delete font-size-18 text-danger" />
                              </Link>
                            </>
                          )}
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            )}
          </div>
          <CustomPagination
            itemCount={itemCount}
            currentPage={page}
            totalPage={pageCount}
            defaultPageSize={pageSize}
            pageSizeOptions={[5, 10, 20, 30, 40, 50, 100]}
            onChangePage={setPage}
            onPageSizeChange={setPageSize}
          />
        </CardBody>
      </Card>
      <ModalForm
        title="Add Transactions"
        isOpen={modalAddIsOpen}
        toggle={() => setModalAddIsOpen(!modalAddIsOpen)}
        onSubmit={handleSubmit}
      />
      {item && (
        <ModalForm
          title="Edit Transaction"
          item={item}
          isOpen={modalEditIsOpen}
          toggle={() => setModalEditIsOpen(!modalEditIsOpen)}
          onSubmit={handleUpdate}
        />
      )}
      <ConfirmDeleteModal
        title="Confirm Delete"
        isOpen={modalConfirmDeleteIsOpen}
        toggle={() => setModalConfirmDeleteIsOpen(!modalConfirmDeleteIsOpen)}
        onSubmit={handleDelete}
      />
    </div>
  );
};

WalletTransactionTab.propTypes = {
  walletId: PropTypes.string.isRequired,
  items: PropTypes.array.isRequired,
  item: PropTypes.object,
};

const mapStateToProps = ({ walletTransaction }) => {
    return walletTransaction;
};

export default withRouter(
  connect(mapStateToProps, {
    getWalletTransactions,
    addNewWalletTransaction,
    getWalletTransactionById,
    updateWalletTransaction,
    deleteWalletTransaction,
    clearWalletTransactionError,
  })(WalletTransactionTab)
);

