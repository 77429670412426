import React, { useEffect, useState } from "react";
import { Button, Col, FormGroup, Input, Label, Modal, Row } from "reactstrap";
import PropTypes from "prop-types";
import { NIL } from "uuid";
import Select from "react-select";
import NumberFormat from "react-number-format";
import Gallery from "react-fine-uploader";
import FineUploaderTraditional from "fine-uploader-wrappers";

let uploader;

const ModalForm = (props) => {
  const { isOpen, toggle, title, onSubmit, item: initialItem } = props;

  const [item, setItem] = useState({
    id: NIL,
    amount: "",
    description: "",
    walletId: null,
    type: null,
    walletTransactionAttachments: [],
    status: { value: 0, label: "Draft" },
  });
  const [walletTransactionAttachments, setWalletTransactionAttachments] = useState([]);
  
  const [submitted, setSubmitted] = useState(false);
  const [fileName, setFileName] = useState('');

    const transactionOptions = [
    { value: 0, label: "Deposit" },
    { value: 1, label: "Withdrawal" },
    { value: 2, label: "Transfer" },
  ];

  const statusOptions = [
    { value: 0, label: "Draft" },
    { value: 1, label: "Pending" },
    { value: 2, label: "Confirmed" },
    { value: 3, label: "Rejected" },
  ];
  
  const handleOnChange = (e) => {
    const { name, value } = e.target;
    setItem({
      ...item,
      [name]: value,
    });
  };
  
  const handleSelectChange = (valueType, actionMeta) => {
    const { name } = actionMeta

    setItem({
      ...item,
      [name]: valueType
    })
  }

  useEffect(() => {
    uploader = new FineUploaderTraditional({
      options: {
        multiple: true,
        request: {
          method: "POST",
          endpoint: `${process.env.REACT_APP_API_URL}/api/FileUpload/walletTransactionAttachment`,
        },
        validation: {
          sizeLimit: 104857600, // 100MB
        },
      },
    });

    uploader.on("complete", (id, name, responseJSON) => {
      if (responseJSON.success) {
        const attachment = {
          fileName: responseJSON.fileName,
          url: responseJSON.url,
          id: responseJSON.id,
        };
        
        setWalletTransactionAttachments(prev => 
          prev.some(att => att.url === attachment.url) ? prev : prev.concat(attachment)
        );
      }
    });

    uploader.on("error", (id, name, errorReason) => {
      console.log("Upload error:", errorReason, id, name);
    });
    
  }, []);

  const onOpened = () => {
    const { item } = props

    if (item) {
      setItem({
        ...item,
        id: item.id || NIL,
        amount: item.amount || "",
        description: item.description || "",
        walletId: item.walletId || null,
        type: item.type !== undefined
          ? { value: item.type, label: item.typeName }
          : null,
        status: item.status !== undefined
          ? statusOptions.find((option) => option.value === item.status)
          : null,
      });
      
      setWalletTransactionAttachments((initialItem.walletTransactionAttachments || []).filter((attachment, index, self) =>
        index === self.findIndex(att => att.url === attachment.url)
      ));
      
      setFileName(initialItem.fileName || '');
      setSubmitted(false);
      
    } else {
      setItem({
        id: NIL,
        amount: "",
        description: "",
        walletId: null,
        type: { value: 0, label: "Deposit" },
        status: { value: 0, label: "Draft" },
      });
      setFileName('');
      setSubmitted(false);
    }
  }
 
  const handleSubmit = () => {
    setSubmitted(true);
    const isValid = item.amount && item.type && item.status;
    
    if (isValid) {
      const data = {
        ...item,
        id: item.id !== NIL ? item.id : undefined,
        type: item.type?.value,
        status: item.status?.value,
        walletTransactionAttachments,
      };
      onSubmit(data);
    }
  };

  return (
    <Modal
      size="md"
      onOpened={onOpened}
      isOpen={isOpen}
      toggle={toggle}
      backdrop="static"
    >
      <div className="modal-header">
        <h5 className="modal-title mt-0" id="myModalLabel">
          {title}
        </h5>
        <button
          type="button"
          onClick={toggle}
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>

      <div className="modal-body">
        <Row>
          <Col>
            <FormGroup className="mb-3">
              <Label htmlFor="">Amount</Label>
              <NumberFormat
                id="amount"
                name="amount"
                value={ item.amount }
                className={ "form-control text-end" }
                placeholder="0"
                thousandSeparator={ true }
                fixedDecimalScale={ false }
                onValueChange={ values => {
                  const { floatValue } = values;
                  setItem({
                    ...item,
                    amount: floatValue,
                  })
                } }
              />
              {submitted && !item.amount && (
                <div className="invalid-feedback-custom">Amount is required.</div>
              )}
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col>
            <FormGroup className="mb-3">
              <Label>Transaction Type</Label>
              <Select
                name="type"
                value={item.type}
                onChange={handleSelectChange}
                options={transactionOptions}
                classNamePrefix="select2-selection"
                isClearable
              />
              {submitted && !item.type && (
                <div className="invalid-feedback-custom">
                  Transaction Type is required.
                </div>
              )}
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col>
            <FormGroup className="mb-3">
              <Label>Status</Label>
              <Select
                name="status"
                value={item.status}
                onChange={handleSelectChange}
                options={statusOptions}
                classNamePrefix="select2-selection"
                isClearable
              />
              {submitted && !item.status && (
                <div className="invalid-feedback-custom">Status is required.</div>
              )}
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col>
            <FormGroup className="mb-3">
              <Label>Description</Label>
              <Input
                type="textarea"
                rows={3}
                name="description"
                placeholder="Description"
                value={item.description}
                onChange={handleOnChange}
              />
              {submitted && !item.description}
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col>
            {walletTransactionAttachments && walletTransactionAttachments.length > 0 ? (
              <div>
                <ul style={{ listStyleType: "none", padding: 0 }}>
                  {walletTransactionAttachments.map((attachment) => (
                    <li key={attachment.id} style={{ display: "flex", alignItems: "center", marginBottom: "5px" }}>
                      <i className="fas fa-download" style={{ marginRight: "8px", color: "blue" }}></i>
                      <a href={attachment.url} target="_blank" rel="noopener noreferrer">
                        {attachment.fileName}
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
            ) : (
              <></>
            )}
            {uploader && <Gallery uploader={uploader} />}
          </Col>
        </Row>
      </div>
      <div className="modal-footer">
        <Button color="primary" onClick={handleSubmit} type="submit">
          Submit
        </Button>
        <button
          type="button"
          onClick={toggle}
          className="btn btn-secondary"
          data-dismiss="modal"
        >
          Close
        </button>
      </div>
    </Modal>
  );
};

ModalForm.propTypes = {
  item: PropTypes.object,
  isOpen: PropTypes.bool.isRequired,
};

export default ModalForm;
