import CustomPagination from "components/Common/CustomPagination";
import React from "react";
import PropTypes from "prop-types";
import {
  Card,
  CardBody,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Table,
  UncontrolledDropdown
} from "reactstrap";
import moment from "moment";
import NumberFormat from "react-number-format";

const SaleOrderTable = ({
                          itemCount,
                          page,
                          totalPage,
                          defaultPageSize,
                          items,
                          onEdit,
                          onGenerateInvoice,
                          onDownloadPrintLabel,
                          onGenerateStockOut,
                          onChangePage,
                          onPageSizeChange,
                          onStatusChange,
                        }) => {

  const displayStatus = item => {
    let color = ""
    const { status, statusName } = item
    switch (status) {
      case 0:
        color = "info"
        break
      case 1:
        color = "warning"
        break
      case 2:
        color = "success"
        break
      case 3:
        color = "dark"
        break
      default:
        color='info'
        break;
    }
    return (
        <p className={ `m-0 p-0 text-${ color }` }>
          { statusName }
        </p>
        // <Badge color={ color } className="px-2 py-2 btn-xs btn-outline">
        //   <span>{ statusName }</span>
        // </Badge>
    )
  }

  const displayPaymentStatus = item => {
    let color = ""
    const { paymentStatus, paymentStatusName } = item
    switch (paymentStatus) {
      case 0:
        // Pending = 0,
        color = "info"
        break
      case 1:
        // Authorized = 1,
        color = "primary"
        break
      case 2:
        // Paid = 2,
        color = "success"
        break
      case 3:
        // PartiallyRefunded = 3
        color = "danger"
        break
      case 4:
        // Refunded
        color = "secondary"
        break

      case 5:
        // Voided
        color = "danger"
        break

      case 6:
        // Voided
        color = "danger"
        break
    }
    return (
        <p className={ `m-0 p-0 text-${ color }` }>
          { paymentStatusName }
        </p>
        // <Badge color={ color } className="px-2 py-2 btn-xs btn-outline">
        //   <span>{ paymentStatusName }</span>
        // </Badge>
    )
  }

  const displayDeliveryStatus = item => {
    let color = ""
    const { deliveryStatus, deliveryStatusName } = item
    switch (deliveryStatus) {
      case 0:
        // NotYetShipped = 0,
        color = "info"
        break
      case 1:
        // ShippingNotRequired = 1,
        color = "warning"
        break
      case 2:
        // PartiallyShipped = 2,
        color = "primary"
        break
      case 3:
        // Shipped = 3,
        color = "success"
        break
      case 4:
        // Delivered = 4,
        color = "dark"
        break
      default:
        color='info'
        break;
    }
    return (
        <p className={ `m-0 p-0 text-${ color }` }>
          { deliveryStatusName }
        </p>
        // <Badge color={ color } className="px-2 py-2 btn-xs btn-outline">
        //   <span>{ shippingStatusName }</span>
        // </Badge>
    )
  }


  return (
      <Card>
        <CardBody>
          <div className="table-rep-plugin">

            <div
                className="table-responsive mb-0"
                data-pattern="priority-columns"
            >
              <Table
                  id="tech-companies-1"
                  className="table table-striped table-bordered table-nowrap"
              >
                <thead>
                <tr>
                  <th className={ "text-center" } style={ { width: "80px" } }>#</th>
                  <th className={ "text-center" } style={ { width: "120px" } }>Action</th>
                  <th>Order No</th>
                  <th>Customer</th>
                  <th>Type</th>
                  <th>Payment Method</th>
                  <th>Amount</th>
                  <th>Order Status</th>
                  <th>Payment Status</th>
                  <th>Delivery Status</th>
                  <th>Seller</th>
                  <th>Created Date</th>
                </tr>
                </thead>
                <tbody>
                { items.map((item, index) => {
                  let num = (page - 1) * defaultPageSize + (index + 1);
                  return (
                      <tr key={ index } style={ { cursor: "pointer" } } onDoubleClick={ () => onEdit(item.id) }>
                        <td style={ { textAlign: "right" } }>{ num }</td>
                        <td>
                          <div className="text-center">
                            <UncontrolledDropdown>
                              <DropdownToggle className="card-drop" tag="a">
                                <i className="mdi mdi-dots-horizontal font-size-18"/>
                              </DropdownToggle>
                              <DropdownMenu className="dropdown-menu-start">
                                <DropdownItem onClick={ () => onEdit(item.id) }>
                                  <i className="mdi mdi-clipboard-list-outline font-size-16 text-primary me-1"/>{ " " }
                                  Detail
                                </DropdownItem>

                                {
                                    [0].includes(item.status) &&  // Change from Pending to Progressing
                                    <DropdownItem onClick={ () => onStatusChange({id: item.id, status: 1}) }>
                                      <i className="fas fa-truck font-size-16 text-primary me-1"/>{ " " }
                                      Change to Processing
                                    </DropdownItem>
                                }

                                {[1].includes(item.status) &&
                                    <>
                                      {
                                        // Change from Progressing back to Pending
                                      }
                                      <DropdownItem onClick={ () => onStatusChange({id: item.id, status: 0}) }>
                                        <i className="fas fa-truck-loading font-size-16 text-primary me-1"/>{ " " }
                                        Change to Pending
                                      </DropdownItem>

                                      {
                                        // Change from Progressing to Completed
                                      }
                                      <DropdownItem onClick={ () => onStatusChange({id: item.id, status: 2}) }>
                                        <i className="fas fa-check-circle font-size-16 text-primary me-1"/>{ " " }
                                        Change to Completed
                                      </DropdownItem>
                                    </>
                                }

                                <DropdownItem divider/>
                                <DropdownItem
                                    onClick={() => onDownloadPrintLabel(item.id)}
                                >
                                  <i className="fas fa-download text-primary font-size-16 me-1" />{" "}
                                  Download Label
                                </DropdownItem>
                                <DropdownItem
                                  onClick={ () => onGenerateStockOut(item) }
                                >
                                  <i className="fas fa-arrow-up font-size-16 text-primary me-1"/>{ " " }
                                  Generate Stock Out
                                </DropdownItem>
                                <DropdownItem
                                    onClick={ () => onGenerateInvoice(item) }
                                >
                                  <i className="bx bx-receipt font-size-16 text-info me-1"/>{ " " }
                                  Generate Invoice
                                </DropdownItem>
                              </DropdownMenu>
                            </UncontrolledDropdown>
                          </div>
                        </td>
                        <td>{ item.orderNo }</td>
                        <td>{ item.customerName }</td>
                        <td>{ item.typeName }</td>
                        <td>{ item.paymentMethodName }</td>
                        <td className="text-end">
                          <NumberFormat
                              value={ item.total }
                              displayType="text"
                              thousandSeparator={ true }
                              fixedDecimalScale={ false }
                              prefix={ "$" }
                          />
                        </td>
                        <td>{ displayStatus(item) }</td>
                        <td>{ displayPaymentStatus(item) }</td>
                        <td>{ displayDeliveryStatus(item) }</td>
                        <td>{ item.sellerName }</td>
                        <td className={ "text-end" }>{ moment.utc(item.createdDate).local().format("DD-MMM-yyyy h:mm:ss a") }</td>  
                      </tr>
                  );
                }) }
                </tbody>
              </Table>
            </div>
          </div>
          <CustomPagination
              itemCount={ itemCount }
              currentPage={ page }
              totalPage={ totalPage }
              defaultPageSize={ defaultPageSize }
              pageSizeOptions={ [ 5, 10, 20, 30, 40, 50, 100 ] }
              onChangePage={ i => onChangePage(i) }
              onPageSizeChange={ size => onPageSizeChange(size) }
          />
        </CardBody>
      </Card>
  );
};

SaleOrderTable.propTypes = {
  itemCount: PropTypes.number,
  page: PropTypes.number,
  totalPage: PropTypes.number,
  defaultPageSize: PropTypes.number,
  items: PropTypes.array,
  loading: PropTypes.bool,
  onEdit: PropTypes.func,
  onConfirmDelete: PropTypes.func,
  onAddNew: PropTypes.func,
  onSearch: PropTypes.func,
  onChangePage: PropTypes.func,
  onPageSizeChange: PropTypes.func,
  onStatusChange: PropTypes.func,
};

export default SaleOrderTable;
